
import { useI18n } from 'vue-i18n';
import { defineComponent, ref, Ref, computed, PropType, onMounted } from 'vue';
import {
  Supplement,
  PatientSupplementDto,
  PatientSupplement,
  patientSupplementForm,
  isPatientFormCreateValid,
  SupplementUnit,
  SupplementType,
} from '@/models/Supplement';
import { Patient } from '@/models/Patient';
import { supplementsService } from '@/services/SupplementsService';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
  emits: ['on-create-supplement'],
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const showDialog = ref(false);
    const showValidation = ref(false);
    const supplementPatient: Ref<PatientSupplement> = ref({} as PatientSupplement);
    const supplementPatientFormDto: Ref<PatientSupplementDto> = ref({} as PatientSupplementDto);
    const allAvailableSupplements = ref([] as Supplement[]);
    const validateForm = computed(() => patientSupplementForm(supplementPatientFormDto.value));
    const waitSubmit = ref(false);
    const valid = computed(() => isPatientFormCreateValid(validateForm.value));

    const minEndDate = computed(() => {
      return supplementPatientFormDto.value.startDate || new Date();
    });

    const logoSupplement = ref(window.location.origin + '/assets/icon/supplements/supplement.jpg');
    const labelAmount = computed(() => {
      if (supplementPatientFormDto.value.unit) {
        return `${t('supplementation.patient.create-modal.amount', {
          unit: t(`supplementation.unit.${supplementPatientFormDto.value.unit}`),
        })}`;
      } else {
        return t('supplementation.patient.create-modal.amount', { unit: '' });
      }
    });

    onMounted(async () => {
      await fecthSupplements();
    });

    const supplementsOptions = computed(() => {
      return allAvailableSupplements.value.map((supplement) => ({
        label: supplement.name,
        value: supplement.id,
      }));
    });

    const selectedSupplement = computed(() => {
      return allAvailableSupplements.value.find(
        (supplement) => supplement.id === supplementPatientFormDto.value.supplementId,
      );
    });
    const deleteSelectedSupplement = () => {
      supplementPatientFormDto.value.supplementId = '';
    };

    const fecthSupplements = async () => {
      const result = await supplementsService.findAll();
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorLoadSupplements')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        allAvailableSupplements.value = result;
      }
    };

    const numberOfTakesOptions = computed(() => {
      const array: { label: string; value: number }[] = [];
      for (let i = 1; i <= 10; i += 1) {
        array.push({ label: i.toString(), value: i });
      }
      return array;
    });

    const unitsOptions = computed(() => {
      return Object.keys(SupplementUnit).map((key) => ({
        label: t(`supplementation.unit.${key}`),
        value: key,
      }));
    });

    const openDialog = (defaultPatientSupplement: PatientSupplement) => {
      if (!defaultPatientSupplement) {
        return;
      }
      showValidation.value = false;
      supplementPatient.value = defaultPatientSupplement;
      supplementPatientFormDto.value = new PatientSupplementDto(defaultPatientSupplement);
      showDialog.value = true;
    };

    const onSubmit = async () => {
      showValidation.value = true;
      if (!valid.value) {
        return;
      }
      createSupplement(supplementPatientFormDto.value);
    };

    async function createSupplement(newSupplementPatient: PatientSupplementDto) {
      waitSubmit.value = true;
      const result = await supplementsService.createByPatient(newSupplementPatient, props.patient.id);
      waitSubmit.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorCreateSupplement')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({
          severity: 'success',
          summary: `${t('messages.notifications.successCreateSupplement')}`,
          life: 3000,
        });
        emit('on-create-supplement');
        showDialog.value = false;
      }
    }

    const renderTypes = (types?: SupplementType[]) => {
      if (!types) {
        return '';
      }

      const translatedTypes = types
        .filter((type) => Object.keys(SupplementType).includes(type))
        .map((type) => t(`supplementation.type.${type}`));
      return translatedTypes.join(', ');
    };

    return {
      showDialog,
      openDialog,
      waitSubmit,
      showValidation,
      supplementPatientFormDto,
      validateForm,
      onSubmit,
      unitsOptions,
      numberOfTakesOptions,
      supplementsOptions,
      labelAmount,
      logoSupplement,
      deleteSelectedSupplement,
      selectedSupplement,
      minEndDate,
      renderTypes,
    };
  },
});
